import pdProtection from '../../../assets/files/ru/personal_data_processing_policy.pdf';
import pdListOfThirdParties from '../../../assets/files/ru/personal_data_processing_policy_30_07_2024.pdf';

export default {
    seo: {
      title: 'Защита ПД | Банк 131',
      description: 'Защита ПД'
    },
    title: 'Защита ПД',
    content: [
        {
          label: 'Дата размещения 19.09.2023',
          title: 'Политика ООО «Банк 131» в отношении обработки персональных данных.PDF',
          link: pdProtection
        },
        {
          label: 'Дата размещения 30.07.2024',
          title: 'Перечень третьих лиц, которые осуществляют обработку персональных данных клиентов на основании заключенных ими с ООО «Банк 131» договоров, согласий клиентов.PDF',
          link: pdListOfThirdParties
        },
    ]
}